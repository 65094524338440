import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { color } from "theme";
import { Input, Button } from "Components";
import { useAuth } from "Context/AuthContext";
import axios from "axios";
import "./style.css";

export const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/");
  };
  // const handleNavigate = () => {
  //     navigate("/reset-password");
  // };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (!data.email) {
      alert("Eamil is Required");
    } else if (!data.password) {
      alert("Password is Required");
    } else {
      axios
        .post("https://tony-backend-248r.onrender.com/api/auth/login", data)
        .then((res) => {
          auth.login(res.data.data.token);
          navigate("/dashboard/products");
        })
        .catch((error) => {
          alert(error.message);
          console.error(error);
        });
    }
  };
  return (
    <div className="main_container">
      <div className="flex_c gap-40 auth_page">
        <div className="flex_c gap-20">
          <h1 style={{ color: color.titleColor }}>Login</h1>
          <p style={{ color: color.textColor }}>
            Login to your Dashboard and enjoy your full access{" "}
          </p>
        </div>

        <form className="flex_c" onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            required
            label="Email Address"
            className="login_input"
            placeholder="example@gmail.com"
            onChange={handleChange}
          />

          <Input
            type="password"
            label="Password"
            name="password"
            className="login_input"
            placeholder="***********"
            onChange={handleChange}
            minLength={8}
            maxLength={12}
          />

          {/* <p className="forget_password" onClick={handleNavigate}>
                        <u>Don't have an account</u>
                    </p> */}
          {/* {error && <div style={{ color: 'red' }}>{error}</div>} */}
          <Button
            name={"Login"}
            type="submit"
            onClick={handleLogin}
            className="login_btn"
          />
        </form>
      </div>
    </div>
  );
};
