import React from "react";
import Whatsapp from "Assets/whatsapp.png";

export const WhatsappIcon = ({ className }) => {
  return (
    <img
      src={Whatsapp}
      alt="whatsapp"
      className={className}
      style={{
        width: "110px",
        position: "fixed",
        right: "5%",
        bottom: "5%",
        zIndex: "11",
      }}
    />
  );
};
